<template>
    <NuxtLayout>
        <NuxtLoadingIndicator :height="3" :duration="2000" :throttle="0" />
        <NuxtPage />
    </NuxtLayout>
</template>

<style lang="scss">
.nuxt-loading-indicator {
    box-shadow: 0px 1px 6px 0px rgba($color: #000000, $alpha: 0.25);
}
</style>
