export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover"},{"name":"robots","content":"noindex,nofollow"},{"name":"description","content":"Your future AI assistant."},{"name":"HandheldFriendly","content":"true"},{"property":"og:title","content":"Your future AI assistant."},{"hid":"og:locale","property":"og:locale","content":"en_US"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon-16x16.png","sizes":"16x16"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png","sizes":"180x180"},{"rel":"mask-icon","href":"/safari-pinned-tab.svg","color":"#6366f1"}],"style":[],"script":[{"src":"https://telegram.org/js/telegram-web-app.js"},{"src":"https://cdn.jsdelivr.net/npm/eruda"}],"noscript":[],"title":"UtureCard","htmlAttrs":{"lang":"en-US"}}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appRootId = "__uture"

export const appBuildAssetsDir = "/_uture/"

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__uture'

export const viewTransition = false