import { default as ban8vlVtzetasMeta } from "/vercel/path0/pages/ban.vue?macro=true";
import { default as _3de7YMbHBs42Meta } from "/vercel/path0/pages/card/3d.vue?macro=true";
import { default as createaz1TBQaLNdMeta } from "/vercel/path0/pages/card/create.vue?macro=true";
import { default as nobe_create9S1GMVdtHuMeta } from "/vercel/path0/pages/card/nobe_create.vue?macro=true";
import { default as showUdTkUGPr1hMeta } from "/vercel/path0/pages/card/show.vue?macro=true";
import { default as topupSyEkarXAYrMeta } from "/vercel/path0/pages/card/topup.vue?macro=true";
import { default as editbDd7SarYgVMeta } from "/vercel/path0/pages/character/edit.vue?macro=true";
import { default as indexk1a5xK1bIqMeta } from "/vercel/path0/pages/character/index.vue?macro=true";
import { default as indexT5E3aPxQQXMeta } from "/vercel/path0/pages/discover/index.vue?macro=true";
import { default as index4rgAf2YMG8Meta } from "/vercel/path0/pages/document/index.vue?macro=true";
import { default as errorTD3gxBm2RgMeta } from "/vercel/path0/pages/error.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as policyNKohT57BqpMeta } from "/vercel/path0/pages/policy.vue?macro=true";
import { default as indexIhrhCp8oIFMeta } from "/vercel/path0/pages/subscribe/index.vue?macro=true";
import { default as termsq5qlZHSSonMeta } from "/vercel/path0/pages/terms.vue?macro=true";
import { default as financewIHIDJNBbGMeta } from "/vercel/path0/pages/user/finance.vue?macro=true";
import { default as invitejo2ZdH92uqMeta } from "/vercel/path0/pages/user/invite.vue?macro=true";
import { default as kyclX4wx0BuZbMeta } from "/vercel/path0/pages/user/kyc.vue?macro=true";
import { default as login5NMa4XkAh4Meta } from "/vercel/path0/pages/user/login.vue?macro=true";
import { default as sign_45upFjppEqteEOMeta } from "/vercel/path0/pages/user/sign-up.vue?macro=true";
import { default as topup13pXuATDtrMeta } from "/vercel/path0/pages/user/topup.vue?macro=true";
import { default as vipWOj2bCynThMeta } from "/vercel/path0/pages/user/vip.vue?macro=true";
export default [
  {
    name: ban8vlVtzetasMeta?.name ?? "ban",
    path: ban8vlVtzetasMeta?.path ?? "/ban",
    meta: ban8vlVtzetasMeta || {},
    alias: ban8vlVtzetasMeta?.alias || [],
    redirect: ban8vlVtzetasMeta?.redirect,
    component: () => import("/vercel/path0/pages/ban.vue").then(m => m.default || m)
  },
  {
    name: _3de7YMbHBs42Meta?.name ?? "card-3d",
    path: _3de7YMbHBs42Meta?.path ?? "/card/3d",
    meta: _3de7YMbHBs42Meta || {},
    alias: _3de7YMbHBs42Meta?.alias || [],
    redirect: _3de7YMbHBs42Meta?.redirect,
    component: () => import("/vercel/path0/pages/card/3d.vue").then(m => m.default || m)
  },
  {
    name: createaz1TBQaLNdMeta?.name ?? "card-create",
    path: createaz1TBQaLNdMeta?.path ?? "/card/create",
    meta: createaz1TBQaLNdMeta || {},
    alias: createaz1TBQaLNdMeta?.alias || [],
    redirect: createaz1TBQaLNdMeta?.redirect,
    component: () => import("/vercel/path0/pages/card/create.vue").then(m => m.default || m)
  },
  {
    name: nobe_create9S1GMVdtHuMeta?.name ?? "card-nobe_create",
    path: nobe_create9S1GMVdtHuMeta?.path ?? "/card/nobe_create",
    meta: nobe_create9S1GMVdtHuMeta || {},
    alias: nobe_create9S1GMVdtHuMeta?.alias || [],
    redirect: nobe_create9S1GMVdtHuMeta?.redirect,
    component: () => import("/vercel/path0/pages/card/nobe_create.vue").then(m => m.default || m)
  },
  {
    name: showUdTkUGPr1hMeta?.name ?? "card-show",
    path: showUdTkUGPr1hMeta?.path ?? "/card/show",
    meta: showUdTkUGPr1hMeta || {},
    alias: showUdTkUGPr1hMeta?.alias || [],
    redirect: showUdTkUGPr1hMeta?.redirect,
    component: () => import("/vercel/path0/pages/card/show.vue").then(m => m.default || m)
  },
  {
    name: topupSyEkarXAYrMeta?.name ?? "card-topup",
    path: topupSyEkarXAYrMeta?.path ?? "/card/topup",
    meta: topupSyEkarXAYrMeta || {},
    alias: topupSyEkarXAYrMeta?.alias || [],
    redirect: topupSyEkarXAYrMeta?.redirect,
    component: () => import("/vercel/path0/pages/card/topup.vue").then(m => m.default || m)
  },
  {
    name: editbDd7SarYgVMeta?.name ?? "character-edit",
    path: editbDd7SarYgVMeta?.path ?? "/character/edit",
    meta: editbDd7SarYgVMeta || {},
    alias: editbDd7SarYgVMeta?.alias || [],
    redirect: editbDd7SarYgVMeta?.redirect,
    component: () => import("/vercel/path0/pages/character/edit.vue").then(m => m.default || m)
  },
  {
    name: indexk1a5xK1bIqMeta?.name ?? "character",
    path: indexk1a5xK1bIqMeta?.path ?? "/character",
    meta: indexk1a5xK1bIqMeta || {},
    alias: indexk1a5xK1bIqMeta?.alias || [],
    redirect: indexk1a5xK1bIqMeta?.redirect,
    component: () => import("/vercel/path0/pages/character/index.vue").then(m => m.default || m)
  },
  {
    name: indexT5E3aPxQQXMeta?.name ?? "discover",
    path: indexT5E3aPxQQXMeta?.path ?? "/discover",
    meta: indexT5E3aPxQQXMeta || {},
    alias: indexT5E3aPxQQXMeta?.alias || [],
    redirect: indexT5E3aPxQQXMeta?.redirect,
    component: () => import("/vercel/path0/pages/discover/index.vue").then(m => m.default || m)
  },
  {
    name: index4rgAf2YMG8Meta?.name ?? "document",
    path: index4rgAf2YMG8Meta?.path ?? "/document",
    meta: index4rgAf2YMG8Meta || {},
    alias: index4rgAf2YMG8Meta?.alias || [],
    redirect: index4rgAf2YMG8Meta?.redirect,
    component: () => import("/vercel/path0/pages/document/index.vue").then(m => m.default || m)
  },
  {
    name: errorTD3gxBm2RgMeta?.name ?? "error",
    path: errorTD3gxBm2RgMeta?.path ?? "/error",
    meta: errorTD3gxBm2RgMeta || {},
    alias: errorTD3gxBm2RgMeta?.alias || [],
    redirect: errorTD3gxBm2RgMeta?.redirect,
    component: () => import("/vercel/path0/pages/error.vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq",
    path: faqM4LN7jxnMuMeta?.path ?? "/faq",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: policyNKohT57BqpMeta?.name ?? "policy",
    path: policyNKohT57BqpMeta?.path ?? "/policy",
    meta: policyNKohT57BqpMeta || {},
    alias: policyNKohT57BqpMeta?.alias || [],
    redirect: policyNKohT57BqpMeta?.redirect,
    component: () => import("/vercel/path0/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: indexIhrhCp8oIFMeta?.name ?? "subscribe",
    path: indexIhrhCp8oIFMeta?.path ?? "/subscribe",
    meta: indexIhrhCp8oIFMeta || {},
    alias: indexIhrhCp8oIFMeta?.alias || [],
    redirect: indexIhrhCp8oIFMeta?.redirect,
    component: () => import("/vercel/path0/pages/subscribe/index.vue").then(m => m.default || m)
  },
  {
    name: termsq5qlZHSSonMeta?.name ?? "terms",
    path: termsq5qlZHSSonMeta?.path ?? "/terms",
    meta: termsq5qlZHSSonMeta || {},
    alias: termsq5qlZHSSonMeta?.alias || [],
    redirect: termsq5qlZHSSonMeta?.redirect,
    component: () => import("/vercel/path0/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: financewIHIDJNBbGMeta?.name ?? "user-finance",
    path: financewIHIDJNBbGMeta?.path ?? "/user/finance",
    meta: financewIHIDJNBbGMeta || {},
    alias: financewIHIDJNBbGMeta?.alias || [],
    redirect: financewIHIDJNBbGMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/finance.vue").then(m => m.default || m)
  },
  {
    name: invitejo2ZdH92uqMeta?.name ?? "user-invite",
    path: invitejo2ZdH92uqMeta?.path ?? "/user/invite",
    meta: invitejo2ZdH92uqMeta || {},
    alias: invitejo2ZdH92uqMeta?.alias || [],
    redirect: invitejo2ZdH92uqMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/invite.vue").then(m => m.default || m)
  },
  {
    name: kyclX4wx0BuZbMeta?.name ?? "user-kyc",
    path: kyclX4wx0BuZbMeta?.path ?? "/user/kyc",
    meta: kyclX4wx0BuZbMeta || {},
    alias: kyclX4wx0BuZbMeta?.alias || [],
    redirect: kyclX4wx0BuZbMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/kyc.vue").then(m => m.default || m)
  },
  {
    name: login5NMa4XkAh4Meta?.name ?? "user-login",
    path: login5NMa4XkAh4Meta?.path ?? "/user/login",
    meta: login5NMa4XkAh4Meta || {},
    alias: login5NMa4XkAh4Meta?.alias || [],
    redirect: login5NMa4XkAh4Meta?.redirect,
    component: () => import("/vercel/path0/pages/user/login.vue").then(m => m.default || m)
  },
  {
    name: sign_45upFjppEqteEOMeta?.name ?? "user-sign-up",
    path: sign_45upFjppEqteEOMeta?.path ?? "/user/sign-up",
    meta: sign_45upFjppEqteEOMeta || {},
    alias: sign_45upFjppEqteEOMeta?.alias || [],
    redirect: sign_45upFjppEqteEOMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/sign-up.vue").then(m => m.default || m)
  },
  {
    name: topup13pXuATDtrMeta?.name ?? "user-topup",
    path: topup13pXuATDtrMeta?.path ?? "/user/topup",
    meta: topup13pXuATDtrMeta || {},
    alias: topup13pXuATDtrMeta?.alias || [],
    redirect: topup13pXuATDtrMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/topup.vue").then(m => m.default || m)
  },
  {
    name: vipWOj2bCynThMeta?.name ?? "user-vip",
    path: vipWOj2bCynThMeta?.path ?? "/user/vip",
    meta: vipWOj2bCynThMeta || {},
    alias: vipWOj2bCynThMeta?.alias || [],
    redirect: vipWOj2bCynThMeta?.redirect,
    component: () => import("/vercel/path0/pages/user/vip.vue").then(m => m.default || m)
  }
]