
import * as vercelRuntime$7DKFNzxSrV from '/vercel/path0/node_modules/.pnpm/@nuxt+image-edge@1.0.0-28059208.2abef1b/node_modules/@nuxt/image-edge/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "vercel",
  "domains": [],
  "alias": {}
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$7DKFNzxSrV, defaults: {} }
}
        