import VueGtag from 'vue-gtag';
import { type Router } from 'vue-router';

export default defineNuxtPlugin((nuxtApp) => {
    const router = nuxtApp.$router as Router;
    nuxtApp.vueApp.use(VueGtag, {
        config: {
            id: 'G-LJXB5HPYBE',
        },
    }, router)
})