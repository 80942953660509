import revive_payload_client_0nQVCSORut from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_713fb40cf4b231dfcf16506806954d9c/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uDb5fAv8nF from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_713fb40cf4b231dfcf16506806954d9c/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_V52Jykqize from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_713fb40cf4b231dfcf16506806954d9c/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_RJ7yCmMCUz from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_713fb40cf4b231dfcf16506806954d9c/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_t6iCSAyw5R from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.11_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_hGzjy1DPmF from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_713fb40cf4b231dfcf16506806954d9c/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_30i99BRISS from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.11_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_bx7I5bZ28B from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.11_vue@3.4.21/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_jP1MGxeUWS from "/vercel/path0/node_modules/.pnpm/@nuxt+image-edge@1.0.0-28059208.2abef1b/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import chunk_reload_client_KGbeVdEWhs from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.1_713fb40cf4b231dfcf16506806954d9c/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zOMXzQKOpq from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.4.11/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import Error_client_PhGgD4fOfj from "/vercel/path0/plugins/Error.client.ts";
import GA_client_Xna3CNelq0 from "/vercel/path0/plugins/GA.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
export default [
  revive_payload_client_0nQVCSORut,
  unhead_uDb5fAv8nF,
  router_V52Jykqize,
  check_outdated_build_client_RJ7yCmMCUz,
  plugin_vue3_t6iCSAyw5R,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hGzjy1DPmF,
  composition_30i99BRISS,
  i18n_bx7I5bZ28B,
  plugin_jP1MGxeUWS,
  chunk_reload_client_KGbeVdEWhs,
  plugin_zOMXzQKOpq,
  Error_client_PhGgD4fOfj,
  GA_client_Xna3CNelq0,
  sentry_client_shVUlIjFLk
]